<template>
	<el-form
		ref="infoFormRef"
		:model="infoForm"
		:rules="rules"
		label-width="120px"
		class="my-info-form"
		:size="formSize"
	>
		<!-- <el-form-item label="头像" prop="avatar">
			<el-upload
				class="avatar-uploader"
				action="https://jsonplaceholder.typicode.com/posts/"
				:show-file-list="false"
				:on-success="handleAvatarSuccess"
				:before-upload="beforeAvatarUpload"
			>
				<img v-if="imageUrl" :src="imageUrl" class="avatar" />
				<el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
			</el-upload>
		</el-form-item> -->
		<el-form-item label="姓名" prop="name">
			<el-input v-model="infoForm.name" maxlength="12" />
		</el-form-item>
		<el-form-item label="用户名" prop="realName">
			<el-input v-model="infoForm.realName" maxlength="12" />
		</el-form-item>
		<el-form-item label="手机号" prop="phone">
			<el-input v-model="infoForm.phone" maxlength="11" />
		</el-form-item>
		<el-form-item label="邮箱" prop="email">
			<el-input v-model="infoForm.email" />
		</el-form-item>

		<el-form-item class="padt-20">
			<el-button type="primary" @click="submitForm(infoFormRef)">提交</el-button>
			<el-button @click="resetForm(infoFormRef)">清空</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
import { reactive, ref, defineComponent, onMounted } from 'vue'
import { getUserInfo, updateInfo } from '@/api/system/user'
import { ElMessage } from 'element-plus'

export default defineComponent({
	name: 'MyInfo',
	setup() {
		const formSize = ref('default')
		const infoFormRef = ref(null)
		const infoForm = reactive({
			// avatar: '',
			id: '',
			name: '',
			realName: '',
			phone: '',
			email: '',
		})

		const rules = reactive({
			name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
			realName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
			phone: [
				{ required: true, message: '请输入手机号', trigger: 'blur' },
				{ pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号', trigger: 'blur' },
			],
			email: [
				{ required: true, message: '请输入邮箱', trigger: 'blur' },
				{
					pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/,
					message: '请输入正确的邮箱',
					trigger: 'blur',
				},
			],
		})

		const submitForm = async formEl => {
			if (!formEl) return
			await formEl.validate(valid => {
				if (valid) {
					updateInfo(infoForm).then(res => {
						if (res.code === 200) {
							ElMessage.success('修改信息成功')
						} else {
							ElMessage.error(res.msg)
						}
					})
				}
			})
		}

		const resetForm = formEl => {
			if (!formEl) return
			formEl.resetFields()
		}
		onMounted(async () => {
			try {
				const { code, data, msg } = await getUserInfo()
				if (code !== 200) throw new Error(msg)
				infoForm.id = data.id
				infoForm.avatar = data.avatar
				infoForm.name = data.name
				infoForm.realName = data.realName
				infoForm.phone = data.phone
				infoForm.email = data.email
			} catch (err) {
				console.log(err)
			}
		})
		return {
			formSize,
			infoFormRef,
			infoForm,
			rules,
			submitForm,
			resetForm,
		}
	},
})
</script>
<style lang="less">
.my-info-form {
	width: 500px;
	padding-top: 20px;
	.padt-20 {
		padding-top: 20px;
	}
}
</style>
